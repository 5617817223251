import styled from "@emotion/styled";
import { isDevelopment } from "../../../utils";
import { TypographyStyleProps } from "@livelo/alchemy-types";

const getFont = (font: TypographyStyleProps) => {
  return {
    ...font,
    fontFamily: !isDevelopment() ? "LiveloSans" : font.fontFamily,
  };
};

export const Input = styled.input`
  border: none;
  width: 100%;
  ${({ theme }) => getFont(theme.typography.body5)}
  &:focus {
    outline: none;
  }
  &::placeholder {
    ${({ theme }) => getFont(theme.typography.body5)}
  }
  &::-webkit-input-placeholder {
    ${({ theme }) => getFont(theme.typography.body5)}
  }
`;
