// Package Imports
import { Actions } from "@twilio/flex-webchat-ui";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

// Component Imports
import Buttons from "./Buttons";
import SendAttachment from "./SendAttachment";

// Const Imports
import { defaultCurInteractives } from "../consts";
import { useFileUpload } from "../hooks/useFileUpload";

// Component
const Interactives = ({
  colorTheme,
  manager,
  messageList,
  channelSid,
  channel,
}) => {
  // Refs
  const interactivesContainer = useRef(null);

  // State
  // @ interface Interactives {
  //   type: '' | 'buttons' | 'dropdown' | 'calendar',
  //   options?: {'uuid': string, 'value': string, 'content': string}[],
  //   dropdownLabel?: string
  //   timezone?: "TZ database name" as per: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  // }
  const [curInteractives, setCurInteractives] = useState(
    defaultCurInteractives
  );

  const { fileUpload } = useFileUpload({ channel: channel.source });

  // Effects
  useEffect(() => {
    if (
      messageList.length > 0 &&
      !messageList[messageList.length - 1].isFromMe &&
      messageList[messageList.length - 1].source.state.attributes
        .interactiveWebchatOptions
    ) {
      const lastMessageAttributes =
        messageList[messageList.length - 1].source.state.attributes
          .interactiveWebchatOptions;
      setCurInteractives(lastMessageAttributes);
    } else {
      setCurInteractives(defaultCurInteractives);
    }
  }, [messageList]);

  useEffect(() => {
    if (curInteractives !== defaultCurInteractives) {
      interactivesContainer.current.scrollIntoView();
    }
  }, [curInteractives]);

  // Functions
  const sendMessage = async ({ value }) => {
    const curChannel = await manager.chatClient.getChannelBySid(channelSid);
    curChannel.sendMessage(value);
  };

  const sendLink = ({ content, value }) => {
    if (value.includes("http")) {
      setTimeout(() => {
        window.open(value, "_blank");
      }, 1000);
    } else {
      Actions.invokeAction("SendMessage", {
        channelSid: channelSid,
        body: content,
      });
    }
  };

  const StyledInteractivesContainer = styled.div`
    width: 100%;
    margin-top: 20px;
  `;

  // Render
  return (
    <>
      {curInteractives !== defaultCurInteractives ? (
        <StyledInteractivesContainer ref={interactivesContainer}>
          {curInteractives.type === "buttons" ? (
            <Buttons
              colorTheme={colorTheme.Buttons}
              curInteractives={curInteractives}
              sendMessage={sendMessage}
            />
          ) : curInteractives.type === "link" ? (
            <Buttons
              colorTheme={colorTheme.Buttons}
              curInteractives={curInteractives}
              sendMessage={sendLink}
            />
          ) : curInteractives.type === "attachment" ? (
            <SendAttachment
              colorTheme={colorTheme.Buttons}
              curInteractives={curInteractives}
              sendAttachment={fileUpload}
            />
          ) : (
            <></>
          )}
        </StyledInteractivesContainer>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state) => {
  return {
    messageList:
      state.flex.chat.channels[state.flex.session.channelSid]?.messages,
    channelSid: state.flex.session.channelSid,
  };
};

export default connect(mapStateToProps)(Interactives);
