import { Channel } from "@twilio/flex-ui/src/state/Conferences/Conferences.definitions";
import { Actions } from "@twilio/flex-webchat-ui";
import { useCallback } from "react";

interface UseFileUploadHook {
  channel: Channel;
}

export const useFileUpload = ({ channel }: UseFileUploadHook) => {
  const fileUpload = useCallback(async () => {
    const curChannel = channel;

    const allowedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];

    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = allowedFileTypes.join(",");

    const processFile = async (fileObj: any) => {
      Actions.invokeAction("SendMediaMessage", {
        file: fileObj,
        channelSid: curChannel.sid,
      });
      Actions.invokeAction("DownloadMedia", {
        message: fileObj,
        channelSid: curChannel.sid,
      });
      Actions.invokeAction("AttachFile", {
        file: fileObj,
        channelSid: curChannel.sid,
      });

      await new Promise((resolve) => {
        // Aguarde o processamento completo do anexo
        setTimeout(resolve, 5000); // Simulação de tempo de processamento
      });

      Actions.invokeAction("SendMessage", {
        body: "Aguarde um momento, estamos processando seu arquivo.",
        channelSid: curChannel.sid,
      });
    };

    fileInput.addEventListener("change", async (event) => {
      if (!event) {
        return;
      }

      const target = event.target as HTMLInputElement | null;

      if (!target) {
        return;
      }

      const fileObj = target.files && target.files[0];

      if (!fileObj) {
        console.error("Nenhum arquivo selecionado.");
        return;
      }

      if (!allowedFileTypes.includes(fileObj.type)) {
        Actions.invokeAction("SendMessage", {
          body: "Arquivo inválido. Selecione um anexo PNG, JPEG, JPG, PDF.",
          channelSid: curChannel.sid,
        });

        return; // Trava o código se o tipo de arquivo for inválido
      }

      await processFile(fileObj);
    });

    fileInput.click();
  }, [channel]);

  return { fileUpload };
};
