import { useTheme } from "@emotion/react";
import { Flex } from "@livelo/alchemy-web";
import { MessageBubble } from "../../molecules/MessageBubble";
import { MessageListItemProps } from "@twilio/flex-ui-core/src/components/channel/MessageListItem/MessageListItem.definitions";
import { User } from "@livelo/alchemy-icons/lib/User";
import { useCallback, useEffect, useRef, useState } from "react";
import { CustomButton } from "../../atoms/CustomButton";

export interface CustomMessageListProps
  extends Omit<MessageListItemProps, "channelSid" | "conversationSid"> {
  onEnd: () => void;
  endButtonText: string;
  authorName: string;
}

export const MessageListItem: React.FC<CustomMessageListProps> = ({
  message,
  member,
  authorName,
  avatarUrl,
  hasMarkdownSupport,
  endButtonText,
  onEnd,
}) => {
  const { borderRadius } = useTheme();
  const [showBackButton, setShowBackButton] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const scrollIfIsLastMessage = useCallback(async () => {
    const count = await message.source.channel?.getMessagesCount() || 0;
    if (message.source.index === count - 1) {
      ref.current?.scrollIntoView();
    }
  }, [message.source.channel, message.source.index]);

  useEffect(() => {
    if (
      !!message?.source?.body &&
      message?.source?.body.includes("Se precisar finalizar")
    ) {
      setShowBackButton(true);
    }
  }, [message]);

  useEffect(() => {
    scrollIfIsLastMessage();
  }, [scrollIfIsLastMessage]);

  const Bubble = useCallback(() => {
    return (
      <MessageBubble
        hasMarkdownSupport={hasMarkdownSupport}
        backgroundColor={message?.isFromMe ? "tints.gray._30" : "common.white"}
        authorName={
          message?.isFromMe ? "Você" : member?.friendlyName || authorName
        }
        message={{
          source: {
            body: message?.source?.body || "",
            timestamp: message?.source?.timestamp || new Date(),
          },
          isFromMe: message?.isFromMe || false,
        }}
      />
    );
  }, [
    authorName,
    hasMarkdownSupport,
    member?.friendlyName,
    message?.isFromMe,
    message?.source?.body,
    message?.source?.timestamp,
  ]);

  const Avatar = useCallback(() => {
    return (
      <Flex
        mr={!message?.isFromMe ? "inline._3XS" : undefined}
        ml={message?.isFromMe ? "inline._3XS" : undefined}
        alignItems="flex-end"
      >
        {!!avatarUrl ? (
          <img src={avatarUrl} alt="Default avatar"></img>
        ) : (
          <Flex
            p="inset._3XS"
            backgroundColor={"primary"}
            style={{
              borderRadius: borderRadius.round,
            }}
          >
            <User color="white" />
          </Flex>
        )}
      </Flex>
    );
  }, [avatarUrl, borderRadius.round, message?.isFromMe]);

  if (message?.isFromMe) {
    return (
      <Flex justifyContent="flex-end">
        <Flex ml={"inline._SM"} p="inset._3XS" />
        <Bubble />
        <Avatar />
      </Flex>
    );
  }
  return (
    <Flex
      ref={ref}
      flexDirection="column"
      style={{
        overflow: "hidden",
      }}
    >
      <Flex>
        <Avatar />
        <Bubble />
        <Flex ml={"inline._SM"} p="inset._3XS" />
      </Flex>
      {showBackButton && (
        <Flex mt="stack._2XS" alignSelf="center">
          <CustomButton size="SM" onClick={onEnd}>
            {endButtonText}
          </CustomButton>
        </Flex>
      )}
    </Flex>
  );
};
