// Package Imports
import React from "react";
import { createRoot } from "react-dom/client";

// CSS Imports
import "@livelo/alchemy-tokens/fonts/fonts.css";

// Service worker
import registerServiceWorker from "./registerServiceWorker";
import { Config } from "@twilio/flex-webchat-ui/src/state/AppConfig";
import { AlchemyProvider } from "@livelo/alchemy-web";
import * as Flex from "@twilio/flex-webchat-ui";
import App from "./App";
import { appConfig } from "./webchat-appConfig";
import { createGlobalStyle } from "styled-components";
import { isDevelopment } from "./utils";

const GlobalStyle = createGlobalStyle`
#chatbot-container {
  body {
    margin: 0;
    padding: 0;
    display: flex;
  }

  body {
    position: relative;
    background-size: cover;
  }

  body:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 174px;
    height: 182px;
    margin-left: -87px;
    margin-top: -91px;
    opacity: 0.2;
  }

  .Twilio-RootContainer-default {
    flex-direction: column !important;
    padding: 8px;
    height: 100%;
  }

  .Twilio-RootContainer {
    height: fit-content;
    font-family: unset;
    font-size: unset;
    width: 100%;
    height: 100%;
  }

  .Twilio-MessagingCanvas {
    max-width: unset;
  }

  .Twilio-WelcomeMessage {
    font-family: ${isDevelopment() ? "Livelo Sans" : "LiveloSans"};
    font-weight: 300;
    line-height: 1.2;
    font-size: 14px;
  }
}
`;

interface CustomConfig extends Config {
  context: {
    bot: Object;
  };
}

declare global {
  interface Window {
    appConfig: Config;
  }
}

setTimeout(() => {
  try {
    const loginbot = localStorage.getItem("LogInBot");

    if (loginbot) {
      const parsed = JSON.parse(loginbot);

      (appConfig as CustomConfig).context.bot = parsed;
    }
  } catch {
    //pass
  }

  // @ts-ignore
  Flex.Manager.create({ ...appConfig }).then((manager) => {
    const root = document.getElementById("chatbot-container");

    if (root) {
      createRoot(root).render(
        <AlchemyProvider>
          <GlobalStyle />
          {/* @ts-ignore */}
          <App manager={manager} />
        </AlchemyProvider>
      );
    }
  });
}, 3000);

registerServiceWorker();
