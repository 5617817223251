import { Flex } from "@livelo/alchemy-web";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useEndSession } from "../../../hooks/useEndSession";
import { RootState } from "../../../store";
import { hideModal } from "../../../store/reducers/endChatModal";
import { CustomButton } from "../../atoms/CustomButton";
import { CustomTypography } from "../../atoms/CustomTypography";
import axios from "axios";
import { setShouldRestart } from "../../../store/reducers/manualRestart";
import { pushDataLayer } from "../../../utils/gtmLiv";

interface EndChatModalProps {
  runtimeDomain: string;
  showModal: boolean;
  dispatch: any;
  flex: FlexWebChat.AppState;
  livButtonsRestore: any;
}

const Component: React.FC<EndChatModalProps> = ({
  runtimeDomain,
  showModal,
  dispatch,
  flex,
  livButtonsRestore,
}) => {
  const { endSession } = useEndSession({ runtimeDomain, flex });

  const closeModal = useCallback(() => {
    dispatch(hideModal());

    livButtonsRestore.current =
      FlexWebChat.MessageList.Content.remove("liv-buttons");
  }, [dispatch, livButtonsRestore]);

  const minimizeChat = useCallback(() => {
    if (FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage) {
      FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage.body =
        "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPor aqui, eu posso te **atender sobre diversos assuntos**. É só **clicar no botão** com o assunto que você precisa.";
    }

    FlexWebChat.AppConfig.current().flexFlowSid =
      process.env.REACT_APP_FLEX_FLOW_SID;

    // FlexWebChat.Actions.invokeAction('RestartEngagement');
    FlexWebChat.Actions.invokeAction("MinimizeChat");
    dispatch({
      type: "CHANNEL_UNLOAD",
      meta: {
        channelSid: flex.session.channelSid,
      },
    });

    dispatch({
      engagementStage: "CF_WAITING_ENGAGEMENT",
      type: "CF_SESSION_SET_ENGAGEMENT_STAGE",
    });

    dispatch(setShouldRestart(true));
  }, [dispatch, flex.session.channelSid]);

  const handleClickYes = useCallback(async () => {
    try {
      const response = await endSession();
      const channelSid = Object.getOwnPropertyNames(flex.chat.channels)[0];

      if (response?.status === 200 || 201) {

        const taskSid =
          // @ts-ignore
          flex.chat.channels[channelSid].source?.attributes.taskSid;

        const removeAgentOptions = {
          method: "POST",
          url: `${process.env.REACT_APP_URL_UTILS}/removeAgentFlow`,
          params: {
            channelSid: channelSid,
            taskSid: taskSid,
          },
          headers: {
            "Content-Type": "application/json"
          }
        };

        const updateTaskInativityOptions = {
          method: "GET",
          url: process.env.REACT_APP_URL_SALES_FORCE + '/v1/updateTaskInatividade',
          params: {
            conversationSid: channelSid,
            responseInativity: "cliente",
          },
          headers: {
            "Content-Type": "application/json"
          }
        };

        await axios.request(updateTaskInativityOptions);
        

        closeModal();
        minimizeChat();

        await axios.request(updateTaskInativityOptions);
        await axios.request(removeAgentOptions);
      } else {
        // error
      }
    } catch {
      //error
    }
  }, [closeModal, endSession, minimizeChat]);

  return showModal ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      backgroundColor={"tints.gray._10"}
      style={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
      }}
    >
      <Flex width="70%" justifyContent="center">
        <CustomTypography align="center" variant="body5">
          Tem certeza que deseja encerrar o atendimento Livelo?
        </CustomTypography>
      </Flex>
      <Flex width="40%" justifyContent="space-between" mt="stack._2XS">
        <CustomButton
          size="SM"
          onClick={() => {
            handleClickYes();
            //Evento para tagueamneto do GA para o botão de encerrar/fechar o WebChat
            pushDataLayer({
              event: "event",
              eventCategory: "livelo:webchat",
              eventAction: "clique:botao",
              eventLabel: "encerrar-atendimento" + ":" + "sim",
            })
          }}
        >
          Sim
        </CustomButton>
        <CustomButton
          size="SM"
          onClick={() => {
            closeModal();
            //Evento para tagueamneto do GA para o botão de encerrar/fechar o WebChat
            pushDataLayer({
              event: "event",
              eventCategory: "livelo:webchat",
              eventAction: "clique:botao",
              eventLabel: "encerrar-atendimento" + ":" + "nao",
            })
          }}
        >
          Não
        </CustomButton>
      </Flex>
    </Flex>
  ) : null;
};

const mapStateToProps = (state: RootState) => {
  return {
    showModal: state.endChatModal.showModal,
    flex: state.flex,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch: dispatch,
  };
};

const EndChatModal = connect(mapStateToProps, mapDispatchToProps)(Component);

export { EndChatModal };
