import { useTheme } from "@emotion/react";
import { CommentsFilled } from "@livelo/alchemy-icons/lib/CommentsFilled";
import { Flex } from "@livelo/alchemy-web";
import { EntryPointProps } from "@twilio/flex-webchat-ui/src/components/EntryPoint";
import { useEffect } from "react";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { pushDataLayer } from "../../../utils/gtmLiv";

interface CustomEntryPointProps extends EntryPointProps {
  isEntryPointExpanded: boolean;
  dispatch: any;
  manager: any;
}

export const EntryPoint = (props: CustomEntryPointProps) => {
  const { borderRadius } = useTheme();
  const { isEntryPointExpanded } = props;
  const isMobile = useIsMobile();

  useEffect(() => {
    // We need to communicate with OCC, so it can adjust the position of the entry point
    // based on if it's open or not.
    window.postMessage({ isEntryPointExpanded, isMobile }, "*");
  }, [isEntryPointExpanded, isMobile]);

  useEffect(() => {
    // For some reason, Twilio doesn't really replaces the
    // components. It instead renders them inside a container.
    // If we want to completely hide the entrypoint, we need to
    // Hide the div too
    const initialMenuButtons = document.querySelector(
      ".initial-menu-buttons-container"
    );

    const messageListItems = Array.from(
      document.querySelectorAll(".Twilio-MessageListItem")
    );

    if (initialMenuButtons && messageListItems.length > 1) {
      initialMenuButtons.setAttribute("style", "display: none;");
    }

    if (isEntryPointExpanded) {
      const components = Array.from(
        document.getElementsByClassName("Twilio-EntryPoint")
      );

      if (components.length > 0) {
        components.map((x) => {
          x.setAttribute("style", `display: none;`);

          return x;
        });
      }
    } else {
      if (initialMenuButtons) {
        initialMenuButtons.setAttribute("style", "display: none;");
      }

      const components = Array.from(
        document.getElementsByClassName("Twilio-EntryPoint")
      );

      if (components.length > 0) {
        components.map((x) => {
          x.removeAttribute("style");
          return x;
        });
      }
    }
  }, [isEntryPointExpanded, isMobile]);

  return (
    <Flex
      p="inset._XS"
      backgroundColor={"primary"}
      flexShrink={1}
      style={{
        borderRadius: borderRadius.round,
        height: 56,
      }}
      //Evento para tagueamneto do GA
      onClick={() => {
        pushDataLayer({
          event: "event",
          eventCategory: "livelo:webchat",
          eventAction: "clique:botao-flutuante",
          eventLabel: "webchat" + ":" + "abriu",
        });
      }}>
      <CommentsFilled color='white' />
    </Flex>
  );
};