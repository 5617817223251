import { useTheme } from "@emotion/react";
import { TypographyProps, Typography as T } from "@livelo/alchemy-web";
import { useMemo } from "react";
import { isDevelopment } from "../../../utils";

export interface CustomTypographyProps extends TypographyProps {}

export const CustomTypography: React.FC<CustomTypographyProps> = (props) => {
  const { getTypography } = useTheme();

  const fontConfig = useMemo(() => {
    if (props.variant) {
      const font = getTypography(props.variant);

      return {
        ...font,
        fontFamily: !isDevelopment() ? "LiveloSans" : font.fontFamily,
        textAlign: props.align ? props.align : font.textAlign,
      };
    }
  }, [getTypography, props.align, props.variant]);

  return <T {...props} style={props.variant && { ...fontConfig }} />;
};
