import { Button, Flex } from "@livelo/alchemy-web";
import { File } from "@livelo/alchemy-icons/lib/File";
import { Send } from "@livelo/alchemy-icons/lib/Send";
import { Input } from "../../molecules/Input";
import { MessageInputProps } from "@twilio/flex-ui-core/src/components/channel/MessageInput/MessageInput.definition";
import { useCallback, useState } from "react";
import { Actions } from "@twilio/flex-webchat-ui";
import { useFileUpload } from "../../../hooks/useFileUpload";
import { Channel } from "@twilio/flex-ui/src/state/Conferences/Conferences.definitions";
import { escapeHtml } from "../../../utils/sanitizeInput";

export interface CustomMessageInputProps extends MessageInputProps {
  channel: Channel;
  channelSid: string;
}

export const MessageInput = (props: Partial<CustomMessageInputProps>) => {
  const [value, setValue] = useState("");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { fileUpload } = useFileUpload({
    channel: (props.channel as any).source as Channel,
  });

  const onSubmit = useCallback(async () => {
    if (isSubmiting) {
      return;
    }

    setIsSubmiting(true);
    try {
      if (!value) {
        return;
      }

      if (!props.channel || !props.channelSid) {
        return;
      }

      if (!props) {
        return;
      }

      await Actions.invokeAction("SendMessage", {
        channelSid: props.channelSid,
        channel: props.channel,
        body: escapeHtml(value),
      });
    } catch (e) {
      console.error(e);
    } finally {
      setValue("");
      setIsSubmiting(false);
    }
  }, [isSubmiting, props, value]);

  return (

      <Flex
        p="inset._XS"
        backgroundColor={"common.white"}
        alignItems="center"
        style={{
          minHeight: 80,
        }}
      >
        <Flex flexGrow={1}>
          <Input
            onKeyUp={async (e) => {
              e.preventDefault();

              if (e.key === "Enter") {
                await onSubmit();
              }
            }}
            placeholder="Escreva aqui"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            value={value}
          />
        </Flex>
        <Flex>
          <Button
            color="secondary"
            onClick={(e) => {
              fileUpload();
            }}
            float
            buttonType="link"
          >
            <File />
          </Button>
        </Flex>
        <Flex ml="inline._XS">
          <Button onClick={onSubmit} float buttonType="link">
            <Send color="primary" />
          </Button>
        </Flex>
      </Flex>
  );
};
