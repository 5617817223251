//Tagueamento para o Google Tag Manager para envio de eventos ao GA

export const pushDataLayer = ({
  event,
  eventCategory,
  eventAction,
  eventLabel,
}) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event,
    eventCategory,
    eventAction,
    eventLabel,
  });
  console.log("LivTag", window.dataLayer);
};