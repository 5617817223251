import { connect } from "react-redux";
import { Header } from "../../molecules/Header";
import { Constants } from "./constants";
import { Actions } from "@twilio/flex-webchat-ui";
import { showModal } from "../../../store/reducers/endChatModal";
import { pushDataLayer } from "../../../utils/gtmLiv";

interface HeaderInstanceProps {
  dispatch: any;
}

const Instance: React.FC<HeaderInstanceProps> = ({ dispatch }) => {
  return (
    <Header
      chatName={Constants.chatName}
      status={Constants.status}
      onMinimize={async () => {
        await Actions.invokeAction("MinimizeChat");
        //Evento para tagueamneto do GA para o botão de minimizar o WebChat
        pushDataLayer({
          event: "event",
          eventCategory: "livelo:webchat",
          eventAction: "clique:botao",
          eventLabel: "webchat" + ":" + "minimizou",})
      }}
      onClose={() => {
        dispatch(showModal());
      }}
      onExpand={() => {}}
    />
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch: dispatch,
  };
};

const HeaderInstance = connect(null, mapDispatchToProps)(Instance);

export { HeaderInstance };
