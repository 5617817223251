import { useWindowDimensions } from "@livelo/alchemy-web";

const THRESHOLD = 1024;

export const useIsMobile = () => {
  const { width } = useWindowDimensions();

  if (width < THRESHOLD) {
    return true;
  }

  return false;
};
