import { isDevelopment } from "./utils";
import * as Tokens from "@livelo/alchemy-tokens";

var brandMessageBubbleColors = function (bgColor: any) {
  return {
    Bubble: {
      background: Tokens.ColorsGrayscaleLightest,
      color: Tokens.ColorsGrayscaleBlack,
    },
    Avatar: {
      background: bgColor,
      color: Tokens.ColorsGrayscaleWhite,
    },
    Header: {
      color: "black",
    },
  };
};

var brandedColors = {
  Chat: {
    MessagingCanvas: {
      Container: {
        background: "#F1F1F1",
      },
    },
    MessageListItem: {
      FromOthers: brandMessageBubbleColors(Tokens.ColorsBrandPrimaryMedium),
      FromMe: brandMessageBubbleColors(Tokens.ColorsBrandPrimaryMedium),
    },
    MessageInput: {
      Container: {
        marginTop: "24px",
        background: "white !important",
        required: "required",
      },
      Button: {
        background: Tokens.ColorsGrayscaleWhite,
        color: Tokens.ColorsBrandPrimaryMedium,
      },
    },
    MessageCanvasTray: {
      Container: {
        background: Tokens.ColorsBrandPrimaryMedium,
        color: Tokens.ColorsGrayscaleWhite,
        fontFamily: isDevelopment() ? "Livelo Sans" : "LiveloSans",
        fontWeight: 300,
        fontSize: "14px",
        padding: "0px 8px",
        marginTop: "16px",
        textAlign: "center",
      },
      Button: {
        background: Tokens.ColorsGrayscaleWhite,
        color: Tokens.ColorsBrandPrimaryMedium,
        fontFamily: isDevelopment() ? "Livelo Sans" : "LiveloSans",
        lineHeight: 1.2,
        letterSpacing: "unset",
        fontWeight: 700,
        fontSize: "14px",
        marginTop: "24px",
        minWidth: "40%",
        borderRadius: "500px",
        padding: "0 12px",
      },
    },
  },
  MainContainer: {
    width: "100%",
    borderRadius: "8px 8px 8px 8px",
    marginBottom: "8px",
    maxHeight: "unset",
    position: "unset !important",
  },
  MainHeader: {
    Container: {
      background: Tokens.ColorsBrandPrimaryMedium,
      color: Tokens.ColorsGrayscaleWhite,
      height: "72px",
    },
    Logo: {
      fill: Tokens.ColorsGrayscaleWhite,
    },
  },

  EntryPoint: {
    Container: {
      borderRadius: "100px",
      // width: "201px",
      height: "56px",
      padding: "0px",
      margin: "4px",
      position: "unset !important",
      alignSelf: "flex-end",
      background: Tokens.ColorsBrandPrimaryMedium,
    },
  },

  PreEngagementCanvas: {
    Container: {
      background: Tokens.ColorsGrayscaleLightest,
    },

    Form: {
      SubmitButton: {
        background: Tokens.ColorsBrandPrimaryMedium,
        color: Tokens.ColorsGrayscaleWhite,
      },
    },
  },

  Interactives: {
    Buttons: {
      Primary: {
        background: Tokens.ColorsBrandPrimaryLightest,
        fontColor: Tokens.ColorsBrandSecondaryMedium,
      },
      Hover: {
        background: Tokens.ColorsBrandPrimaryMedium,
        fontColor: Tokens.ColorsGrayscaleWhite,
      },
    },
  },
};

export let appConfig = {
  accountSid: process.env.REACT_APP_ACCOUNT_SID,
  flexFlowSid: process.env.REACT_APP_FLEX_FLOW_SID,
  runtimeDomain: process.env.REACT_APP_URL_WEBCHAT+'/v1',
  colorTheme: {
    overrides: brandedColors,
  },
  context: {
    bot: "",
  },
  markdownSupport: {
    enabled: true,
  },
  fileAttachment: {
    enabled: true,
  },
};
