import { Flex, useWindowDimensions } from "@livelo/alchemy-web";
import { ReactNode, connect } from "react-redux";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { RootState } from "../../../store";
import { useEffect } from "react";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import { setShouldRestart } from "../../../store/reducers/manualRestart";

const Component = ({
  children,
  isEntryPointExpanded,
  visible,
  shouldRestart,
  dispatch
}: {
  children: ReactNode;
  isEntryPointExpanded: boolean;
  visible: boolean;
  shouldRestart: boolean;
  dispatch: Function
}) => {
  const { width, height } = useWindowDimensions();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isEntryPointExpanded && shouldRestart) {
      FlexWebChat.Actions.invokeAction("RestartEngagement");
      dispatch(setShouldRestart(false))
    }
  }, [dispatch, isEntryPointExpanded, shouldRestart]);

  if (!isMobile) {
    if (isEntryPointExpanded) {
      return (
        <Flex
          width={370}
          height={480}
          style={{
            display: visible ? undefined : "none",
          }}
        >
          {children}
        </Flex>
      );
    }

    return <Flex>{children}</Flex>;
  }

  if (!isEntryPointExpanded) {
    return (
      <Flex
        style={{
          width: "fit-content",
          display: visible ? undefined : "none",
        }}
      >
        {children}
      </Flex>
    );
  }

  return (
    <Flex
      width={width}
      height={height}
      style={{
        display: visible ? undefined : "none",
      }}
    >
      {children}
    </Flex>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isEntryPointExpanded: state.flex.session.isEntryPointExpanded,
    shouldRestart: state.manualRestart.shouldRestart,
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    dispatch: dispatch,
  };
};

const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export { MainContainer };
