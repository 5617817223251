import { useTheme } from "@emotion/react";
import { useMemo } from "react";
import { Button, ButtonProps } from "@livelo/alchemy-web";
import { isDevelopment } from "../../../utils";
import styled from "styled-components";

interface CustomButtonProps extends ButtonProps {}

export const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { getTypography } = useTheme();

  const fontConfig = useMemo(() => {
    const size = props.size || "MD";

    let font;

    if (size === "SM") {
      font = getTypography("button2");
    } else {
      font = getTypography("button1");
    }

    return {
      ...font,
      fontFamily: !isDevelopment() ? "LiveloSans" : font.fontFamily,
    };
  }, [getTypography, props.size]);

  const StyledButton = styled(Button)`
    div {
      ${() => ({ ...fontConfig })}
    }
  `;

  return (
    <StyledButton
      {...props}
      style={{ ...fontConfig }}
      buttonStyle={{ ...fontConfig }}
    />
  );
};
