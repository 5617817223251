import { useTheme } from "@emotion/react";
import { ColorType } from "@livelo/alchemy-types";
import { Flex } from "@livelo/alchemy-web";
import { useCallback, useEffect, useState } from "react";
import { CustomTypography } from "../../atoms/CustomTypography";
import Markdown from "markdown-to-jsx";
import { isDevelopment } from "../../../utils";

interface MessageBubbleProps {
  backgroundColor: ColorType;
  authorName: string;
  message: {
    source: {
      body: string;
      timestamp: Date;
    };
    isFromMe: boolean;
  };
  hasMarkdownSupport?: boolean;
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
  backgroundColor,
  message: {
    source: { body, timestamp },
    isFromMe,
  },
  authorName,
  hasMarkdownSupport,
}) => {
  const [parsedBody, setParsedBody] = useState(body);
  const { borderRadius, getTypography } = useTheme();

  useEffect(() => {
    if (!parsedBody) {
      return;
    }

    function cpfValidator(parsedBody: string) {
      let isValid = true;

      const cleanedCPF = parsedBody.replace(/[.-]/g, '');

      if (typeof parsedBody !== 'string' || !/^\d{11}$/.test(cleanedCPF)) {
        isValid = false;
      }

      if (/^(\d)\1+$/.test(cleanedCPF)) {
        isValid = false;
      }

      let soma = 0;
      let resto;

      for (let i = 1; i <= 9; i++) {
        soma += parseInt(cleanedCPF.substring(i - 1, i)) * (11 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cleanedCPF.substring(9, 10))) {
        isValid = false;
      }

      soma = 0;

      for (let i = 1; i <= 10; i++) {
        soma += parseInt(cleanedCPF.substring(i - 1, i)) * (12 - i);
      }
      resto = (soma * 10) % 11;

      if (resto === 10 || resto === 11) {
        resto = 0;
      }

      if (resto !== parseInt(cleanedCPF.substring(10, 11))) {
        isValid = false;
      }
      return isValid;
    }

    function validatePhoneNumber(parsedBody: string) {
      let isValidNumber = true;
      const telefoneLimpo = parsedBody.replace(/\D/g, '');

      // Verificar se todos os dígitos são iguais (números repetidos não são válidos)
      if (/^(\d)\1+$/.test(telefoneLimpo)) {
        isValidNumber = false;
      }

      // Verificar o tamanho do número de telefone
      if (telefoneLimpo.length !== 11) {
        isValidNumber = false;
      }

      // Remover os dois primeiros dígitos
      const numeroSemDDD = telefoneLimpo.slice(2);

      // Verificar se o número sem DDD começa com o dígito 9
      if (!numeroSemDDD.startsWith('9')) {
        isValidNumber = false;
      }

      return isValidNumber;
    }

    function formatCPF(parsedBody: string) {
      const cleanedCPF = parsedBody.replace(/[.-]/g, '');
      const formattedCPF = cleanedCPF.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4',
      );

      return formattedCPF;
    }

    let parsed;

    if (/^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/.test(parsedBody)) {
      const isValidCPF = cpfValidator(parsedBody);
      const isValidPhoneNumber = validatePhoneNumber(parsedBody);
      //console.log('isValidPhoneNumber', isValidPhoneNumber);

      if (isValidCPF) {
        parsed = formatCPF(parsedBody);
        setParsedBody(parsed);
      } else if (isValidPhoneNumber) {
        //console.log('isValidPhoneNumber', isValidPhoneNumber);
        parsed = parsedBody.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        setParsedBody(parsed);
      } else {
        parsed = parsedBody;
        setParsedBody(parsed);
      }
    } else if (/^\d{3}\d{3}\d{3}\d{2}$/.test(parsedBody)) {
      const isValidCPF = cpfValidator(parsedBody);
      const isValidPhoneNumber = validatePhoneNumber(parsedBody);
      //console.log('isValidPhoneNumber', isValidPhoneNumber);

      if (isValidCPF) {
        parsed = formatCPF(parsedBody);
        setParsedBody(parsed);
      } else if (isValidPhoneNumber) {
        //console.log('isValidPhoneNumber', isValidPhoneNumber);
        parsed = parsedBody.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        setParsedBody(parsed);
      } else {
        parsed = parsedBody;
        setParsedBody(parsed);
      }
    }
  }, [parsedBody]);

  const Text = useCallback(({ children }: { children: string }) => {
    return (
      <CustomTypography variant="body5" color="grayscale.darkest">
        {children}
      </CustomTypography>
    );
  }, []);

  return (
    <Flex
      width={265}
      p="inset._XS"
      backgroundColor={backgroundColor}
      style={{
        borderRadius: borderRadius.SM,
      }}
      flexDirection="column"
    >
      <Flex>
        {!!authorName && (
          <Flex flexGrow={1}>
            <CustomTypography variant="caption3" color="grayscale.dark">
              {authorName}
            </CustomTypography>
          </Flex>
        )}
        <CustomTypography variant="caption3" color="grayscale.dark">
          {timestamp.toTimeString().slice(0, 5)}
        </CustomTypography>
      </Flex>
      <Flex mt="stack._XS">
      {hasMarkdownSupport ? (
          <Markdown
            options={{
              wrapper: ({ children }) => (
                <CustomTypography variant="body5">{children}</CustomTypography>
              ),
              overrides: {
                span: ({ children }) => (
                  <CustomTypography variant="body5" children={children} />
                ),
                strong: ({ children }) => (
                  <span
                    style={{
                      ...getTypography("body6"),
                      fontFamily: isDevelopment() ? "Livelo Sans" : "LiveloSans",
                      textAlign: "unset",
                    }}
                  >
                    {children}
                  </span>
                ),
                div: ({ children }) => (
                  <CustomTypography variant="body5" children={children} />
                ),
                p: ({ children }) => (
                  <CustomTypography variant="body5" children={children} />
                ),
              },
            }}
          >
            {parsedBody}
          </Markdown>
        ) : (
          <Text>{parsedBody}</Text>
        )}
      </Flex>
    </Flex>
  );
};