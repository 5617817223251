import { AppState } from "@twilio/flex-webchat-ui";
import Axios from "axios";

export interface UseEndSessionHookInterface {
  runtimeDomain: string | undefined;
  flex: AppState | undefined;
}

export const useEndSession = ({
  runtimeDomain,
  flex,
}: UseEndSessionHookInterface) => {
  const endSession = () => {
    if (flex && flex.session && flex.session.channelSid && runtimeDomain) {
      const channelSid = flex.session.channelSid;

      const axiosBody = {
        channelSid: channelSid,
      };

      const axiosOptions = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let url = new URL("v1/endChat", runtimeDomain);

      return Axios.post(url.href, axiosBody, axiosOptions);
    }
  };
  return { endSession: endSession };
};
