const defaultCurInteractives = { type: "" };
const defaultDropdownLabel = "Por favor, selecione:";
const loadingPlaceholder = "Carregando...";
const managerStringOverrides = {
  entryPointTagLine: "Atendimento Livelo",
  predefineChatMessageAuthorName: "Liv",
  predefinedChatMessageBody:
  "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPor aqui, eu posso te **atender sobre diversos assuntos**. É só **clicar no botão** com o assunto que você precisa.",
  welcomeMessage: "Bem-vindo ao atendimento digital da Livelo.",
  messageCanvasTrayContent:
    '\n<span style="font-weight: 700">Estou sempre disponível para te atender por aqui!</span>\n<span>É só clicar no botão e digitar o que precisa.</span>',
  messageCanvasTrayButton: "Vamos lá!",
};

export {
  defaultCurInteractives,
  defaultDropdownLabel,
  loadingPlaceholder,
  managerStringOverrides,
};

