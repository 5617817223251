import {
  Reducer,
  combineReducers,
} from "@reduxjs/toolkit";
import * as Flex from "@twilio/flex-webchat-ui";
import endChatModalReducer, {
  endChatModalSlice,
} from "./reducers/endChatModal";
import { AppState } from "@twilio/flex-webchat-ui";
import manualRestartReducer, { manualRestartSlice } from "./reducers/manualRestart";

export const reducers = combineReducers({
  flex: (Flex.WebchatReducer as Reducer<AppState>),
  [endChatModalSlice.name]: endChatModalReducer,
  [manualRestartSlice.name]: manualRestartReducer
});

export type RootState = ReturnType<typeof reducers>;