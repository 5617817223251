import { MessageListItemProps } from "@twilio/flex-ui-core/src/components/channel/MessageListItem/MessageListItem.definitions";
import * as FlexWebChat from "@twilio/flex-webchat-ui";

import axios from "axios";
import { connect } from "react-redux";
import { useEndSession } from "../../../hooks/useEndSession";
import { RootState } from "../../../store";
import { MessageListItem } from "../../organisms/MessageListItem";
import { Constants } from "./constants";

export interface CustomMessageListProps extends MessageListItemProps {
  flex: FlexWebChat.AppState;
}

const Instance: React.FC<CustomMessageListProps> = ({
  flex,
  message,
  avatarUrl,
  authorName,
  hasMarkdownSupport,
  member,
}) => {
  const { endSession } = useEndSession({
    flex,
    // @ts-ignore
    runtimeDomain: flex.config.runtimeDomain,
  });

  return (
    <MessageListItem
      onEnd={async () => {
        const channelSid = Object.getOwnPropertyNames(flex.chat.channels)[0];

        const taskSid =
          // @ts-ignore
          flex.chat.channels[channelSid].source?.attributes.taskSid;
        const messages = flex.chat.channels[channelSid].messages;

        const undefinedAuthorMessages:
          | FlexWebChat.ChatChannelState.MessageState[]
          | { index: number }[] = [];
        let highestIndexMessage = 0;

        messages.forEach((message) => {
          if (message.authorName === undefined) {
            undefinedAuthorMessages.push(message);
          }
        });

        for (let i = 0; i < undefinedAuthorMessages.length; i++) {
          if (undefinedAuthorMessages[i].index > highestIndexMessage) {
            highestIndexMessage = undefinedAuthorMessages[i].index;
          }
        }

        const undefinedAuthorLastMessage = undefinedAuthorMessages.find(
          (message) => message.index === highestIndexMessage
        );

        for (let i = 0; i < messages.length; i++) {
          if (
            messages[i].authorName === "Anonymous" &&
            messages[i].index > (undefinedAuthorLastMessage?.index || -1)
          ) {
            const sendSurveyUrl = process.env.REACT_APP_URL_SURVEY + '/v1/sendSurvey';

            const options = {
              method: "POST",
              url: sendSurveyUrl,
              headers: {
                "Content-Type": "application/json"
              },
              data: { taskSid },
            };


            await axios.request(options);
          } else {
            if (i === messages.length - 1) {
              const endChatMessageUrl =
                process.env.REACT_APP_URL_WEBCHAT + "/v1/endChatMessage";

              try {
                await axios.post(endChatMessageUrl, {
                  taskSid: taskSid,
                });

                endSession();

                const removeAgentOptions = {
                  method: "POST",
                  url: `${process.env.REACT_APP_URL_UTILS}/removeAgentFlow`,
                  params: {
                    channelSid: channelSid,
                    taskSid: taskSid,
                  },
                  headers: {
                    "Content-Type": "application/json"
                  }
                };


                await axios.request(removeAgentOptions);

              } catch {
                await axios.post(endChatMessageUrl, {
                  taskSid: taskSid,
                });

                endSession();
              }
            }
          }
        }
      }}
      endButtonText={Constants.endChatButtonText}
      message={message}
      authorName={authorName || Constants.defaultAuthorName}
      avatarUrl={avatarUrl}
      hasMarkdownSupport={hasMarkdownSupport}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    flex: state.flex,
  };
};

const MessageListItemInstance = connect(mapStateToProps)(Instance);

export { MessageListItemInstance };
