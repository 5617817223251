type PageInfo = { 
  name: string;
  url: string;
  text: string;
  tag?: any;
};

export const livVendedoraUrls: PageInfo[] = [
  {
    name: "Local | Ambiente de testes 1",
    url: "http://localhost:8081/",
    text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Passei para te contar que, com o **Clube Livelo**, você pode **ampliar suas conquistas**!\n\n\nE ainda separei um cupom especial que você pode ganhar **até 60.000 pontos de bônus**.\n\n\nAlém disso, todos os meses você garante que todos os pontos não expirem e garante descontos exclusivos.\n\n\nPara saber mais detalhes, **é só clicar no botão.**",
  },
  {
    name: "Local | Ambiente de testes 2",
    url: "http://localhost:8082/",
    text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Passei para te contar que, com o **Clube Livelo**, você pode **ampliar suas conquistas**!\n\n\nE ainda separei um cupom especial que você pode ganhar **até 60.000 pontos de bônus**.\n\n\nAlém disso, todos os meses você garante que todos os pontos não expirem e garante descontos exclusivos.\n\n\nPara saber mais detalhes, **é só clicar no botão.**",
  },
  {
    name: "Local | Ambiente de testes",
    url: "https://dev-livtwilio-bot.pontoslivelo.com.br/",
    text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
  },
  {
    name: "DEV | Ambiente de testes",
    url: "https://a7815093c1dev-store.occa.ocs.oraclecloud.com/product/LIVClubeLivelo",
    text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
  },
    // Páginas do Clube Livelo
    {
      name: "UAT | Clube Livelo",
      url: "https://uat-shopping.livelo.com.br/product/LIVClubeLivelo",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "UAT | Clube Livelo",
      url: "https://uat-shopping.livelo.com.br/clube-livelo?occsite=points",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "UAT | Clube Livelo",
      url: "https://uat-shopping.livelo.com.br/clube-livelo/produto/LIVClubeLivelo?utm_campaign=menu_clube",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Clube Livelo",
      url: "https://www.livelo.com.br/clube-livelo/produto/LIVClubeLivelo",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Clube Livelo",
      url: "https://www.livelo.com.br/clube-livelo/produto/LIVClubeLivelo?utm_campaign=menu_clube",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Clube Livelo",
      url: "https://www.livelo.com.br/clube",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    {
      name: "PRD | Clube Livelo",
      url: "https://www.livelo.com.br/assine-o-clube",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    // Páginas de Renovação de Pontos
    {
      name: "UAT | Renovação de Pontos",
      url: "https://uat-shopping.livelo.com.br/product/LIVPointsRenewal",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Renovar pontos - Renovação de Pontos",
      url: "https://www.livelo.com.br/product/LIVRenovacaoDePontos",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo.\n\n\nPassei para te contar que ao assinar o Clube Livelo, você vai receber **pontos todos os meses e  mais bônus a cada 3 meses**. Tem **descontos em viagens, compra de pontos e muito mais**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    // Páginas de Transferências
    {
      name: "UAT | Transferência entre contas",
      url: "https://uat-shopping.livelo.com.br/product/LTTTransfer",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode **fazer transferências** com muitos mais benefícios. Além de **transferir seus pontos na hora** pra Azul, Smiles e Latam sem pagar nada por isso.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Renovar pontos - Transferência entre contas",
      url: "https://www.livelo.com.br/transfer%C3%AAncia-entre-contas/produto/LTTTransferencia",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode **fazer transferências** com muitos mais benefícios. Além de **transferir seus pontos na hora** pra Azul, Smiles e Latam sem pagar nada por isso.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Transferência - Smiles",
      url: "https://www.livelo.com.br/smiles/produto/SMLTransfer",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    {
      name: "PRD | Transferência - Transfira seus pontos para outros programas",
      url: "https://www.livelo.com.br/transfira-seus-pontos-para-outros-programas",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode **fazer transferências** com muitos mais benefícios. Além de **transferir seus pontos na hora** pra Azul, Smiles e Latam sem pagar nada por isso.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Transferência - Azul",
      url: "https://www.livelo.com.br/tudoazul/produto/AZLTransfer",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    {
      name: "PRD | Transferência - TAP",
      url: "https://www.livelo.com.br/tap-miles-go/produto/TAPTransfer",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode **fazer transferências** com muitos mais benefícios. Além de **transferir seus pontos na hora** pra Azul, Smiles e Latam sem pagar nada por isso.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Transferência - Latam",
      url: "https://www.livelo.com.br/latam-pass/produto/MTPTransfer",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    // Páginas de Compre & Pontue
    {
      name: "PRD | Compre & Pontue - Amazon",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/amazon/AMZ",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Magazine Luiza",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/magalu/MZL",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Casas Bahia",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/casasbahia/CSB",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Renner",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/renner/RNN",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Ponto",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/ponto/PTF",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Netshoes",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/netshoes/NTS",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Booking.com",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/booking/BOK",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Aliexpress",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/aliexpress/ALB",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Beleza na Web",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/belezanaweb/BLZ",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Compre & Pontue - Extra",
      url: "https://www.livelo.com.br/ganhe-pontos-compre-pontue-partner/extra/EXT",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com **Clube Livelo** você pode fazer compras e **ganhar ainda mais pontos**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    // Páginas de Compra de Pontos
    {
      name: "UAT | Comprar pontos",
      url: "https://uat-shopping.livelo.com.br/compra-de-pontos/produto/LIVCompraDePontos?skuId=LIVSKUCompraDePontos",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode ter até **50% de desconto** em qualquer compra de pontos e até **52% de desconto a cada 4 meses**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "UAT | Compra de pontos - Para presente",
      url: "https://uat-shopping.livelo.com.br/compra-de-pontos/produto/LIVCompraDePontos?skuId=LIVSKUPontosDePresente",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode ter até **50% de desconto** em qualquer compra de pontos e até **52% de desconto a cada 4 meses**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "UAT | Compra de pontos",
      url: "https://uat-shopping.livelo.com.br/compra-de-pontos/produto/LIVPointsPurchase?skuId=LIVSKUPointsPurchase",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode ter até **50% de desconto** em qualquer compra de pontos e até **52% de desconto a cada 4 meses**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Comprar pontos",
      url: "https://www.livelo.com.br/compra-de-pontos/produto/LIVCompraDePontos?skuId=LIVSKUCompraDePontos",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo. Com o **Clube Livelo** você pode ter até **50% de desconto** em qualquer compra de pontos e até **52% de desconto a cada 4 meses**.\n\n\nPara **saber mais detalhes e assinar agora**, é só **clicar no botão**.",
    },
    {
      name: "PRD | Comprar pontos - Pontos de presente",
      url: "https://www.livelo.com.br/compra-de-pontos/produto/LIVCompraDePontos?skuId=LIVSKUPontosDePresente",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    {
      name: "PRD | Renovação de Clubes",
      url: "https://www.livelo.com.br/renovacao-de-pontos",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    {
      name: "PRD | Pagar com Pix",
      url: "https://www.livelo.com.br/pagar-com-pix",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    },
    {
      name: "PRD | CashBack",
      url: "https://www.livelo.com.br/pontos-viram-dinheiro/produto/cashback",
      text: "Olá! Eu sou a Liv, a atendente digital da Livelo, e **tenho uma oferta especial para você**!\n\n\nAssine agora o **Clube Classic Mensal por apenas R$ 44,90 por mês e garanta 25 mil pontos** em 12 meses!\n\n\nÉ só clicar no botão abaixo e usar o **cupom OFERTACLASSIC**.",
    }, 
  ];