// Para substituir de forma global os caracteres citados no regex 

export const escapeHtml = (text) => {
  const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    // '"': '&quot;',
    // "'": '&#39;',
    // '/': '&#x2F;',
    '`': '&#x60;',
    //'=': '&#x3D;'
  };

  // const regex = /[&<>"'`=\/]/g
  const regex = /[&<>`]/g

  return text.replace(regex, (s) => {
    return entityMap[s];
  });
}

// ===================================================================

// Para remover de forma global os caracteres citados no regex 

// export const removeSpecialCharacters = (text) => {
//   const regex = /[%$#:&"´*+\;<=>^]/g

//   return text.replace(regex, "")
// }
