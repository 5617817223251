import * as FlexWebChat from "@twilio/flex-webchat-ui";
import { CustomButton } from "../../../atoms/CustomButton";
import { pushDataLayer } from "../../../../utils/gtmLiv";

export default function InitialMenuButton({ manager, value }) {
  const channelSid = manager.store.getState().flex.session.channelSid;

  //Evento para tagueamneto do GA
  const handleClick = (value) => {
    pushDataLayer({
      event: "event",
      eventCategory: "livelo:webchat",
      eventAction: "clique:botao",
      eventLabel: "webchat" + ":" + value,
    });

    FlexWebChat.Actions.invokeAction("SendMessage", {
      channelSid,
      body: value,
    });
  };

  FlexWebChat.Actions.addListener("afterSendMessage", () => {
    const initialMenuButtons = document.querySelector(
      ".initial-menu-buttons-container"
    );

    if (initialMenuButtons) {
      initialMenuButtons.setAttribute("style", "display: none;");
    }
  });

  return (
    <CustomButton size="SM" key={value} onClick={() => handleClick(value)}>
      {value}
    </CustomButton>
  );
}
