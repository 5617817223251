import InitialMenuButton from "./InitialMenuButton";

const buttonStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: "0.5rem",
  overflowY: "auto",
  maxHeight: "300px",
  padding: "8px",
  textAlign: "center",
};


export default function InitialMenuButtons({ manager }) {

  // const logInBotValue = localStorage.getItem("LogInBot");

  // if (logInBotValue) {
  //   return (
  //     <div className="initial-menu-buttons-container" style={buttonStyle}>
  //       <InitialMenuButton manager={manager} value="The Centurion Card" />
  //       <InitialMenuButton manager={manager} value="Cadastro" />
  //       <InitialMenuButton manager={manager} value="Pontos" />
  //       <InitialMenuButton manager={manager} value="Pedidos" />
  //       <InitialMenuButton manager={manager} value="Clube Livelo" />
  //       <InitialMenuButton manager={manager} value="Viagens" />
  //       <InitialMenuButton manager={manager} value="Transferência" />
  //       <InitialMenuButton manager={manager} value="Outros assuntos" />
  //     </div>
  //   );
  // }

  return (
    <div className="initial-menu-buttons-container" style={buttonStyle}>
      <InitialMenuButton manager={manager} value="Sobre a Livelo" />
      <InitialMenuButton manager={manager} value="Cadastro" />
      <InitialMenuButton manager={manager} value="Pontos" />
      <InitialMenuButton manager={manager} value="Clube Livelo" />
      <InitialMenuButton manager={manager} value="Pedidos" />
      <InitialMenuButton manager={manager} value="Viagens" />
      <InitialMenuButton manager={manager} value="Transferência" />
      <InitialMenuButton manager={manager} value="Campanhas" />
      <InitialMenuButton manager={manager} value="The Centurion Card" />
      <InitialMenuButton manager={manager} value="Outros assuntos" />
    </div>
  );
  

}