import { Button, Flex } from "@livelo/alchemy-web";
import LiveloNegativeLogo from "@livelo/alchemy-tokens/images/negative-logo.svg";
import { useTheme } from "@emotion/react";
import { ErrorL } from "@livelo/alchemy-icons/lib/ErrorL";
import { ChevronDownFilled } from "@livelo/alchemy-icons/lib/ChevronDownFilled";
import { MainHeaderProps } from "@twilio/flex-webchat-ui/src/components/MainHeader";
import { CustomTypography } from "../../atoms/CustomTypography";

export interface CustomHeaderProps extends MainHeaderProps {
  chatName: string;
  status: string;
  onMinimize: () => void;
  onClose: () => void;
  onExpand: () => void;
}

export const Header = ({
  chatName,
  status,
  onMinimize,
  onClose,
  onExpand,
}: CustomHeaderProps) => {
  const { borderRadius } = useTheme();
  return (
    <Flex
      width={"100%"}
      height={72}
      pt="inset._3XS"
      pr="inset._XS"
      pb="inset._3XS"
      pl="inset._XS"
      backgroundColor={"primary"}
      alignItems="center"
      style={{
        borderTopLeftRadius: borderRadius.SM,
        borderTopRightRadius: borderRadius.SM,
      }}
    >
      <Flex>
        <img
          src={LiveloNegativeLogo}
          alt="Livelo Logo"
          width={65}
        ></img>
      </Flex>
      <Flex flexDirection="column" ml="inline._2XS">
        <CustomTypography variant="button1" color="white" align="left">
          {chatName}
        </CustomTypography>
        <CustomTypography variant="caption3" color={"white"}>
          {status}
        </CustomTypography>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" flexGrow={1}>
        <Button onClick={onMinimize} float size="SM">
          <ChevronDownFilled color="white" />
        </Button>
        <Button size={"SM"} onClick={onClose} float>
          <ErrorL color={"white"} />
        </Button>
      </Flex>
    </Flex>
  );
};
