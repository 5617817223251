import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ManualRestartReducer {
  shouldRestart: boolean;
}

const initialState: ManualRestartReducer = {
  shouldRestart: false,
};

export const manualRestartSlice = createSlice({
  name: "manualRestart",
  initialState,
  reducers: {
    setShouldRestart: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldRestart = payload;
    },
  },
});

export const { setShouldRestart } = manualRestartSlice.actions;

export default manualRestartSlice.reducer;
