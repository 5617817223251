import React from "react";
import { Flex } from "@livelo/alchemy-web";
import { CustomButton } from "./atoms/CustomButton";
import { pushDataLayer } from "../utils/gtmLiv";

declare global {
  interface Window {
    dataLayer: any; // 👈️ turn off type checking
  }
}

const Buttons = ({
  curInteractives,
  sendMessage,
}: {
  curInteractives: any;
  sendMessage: any;
}) => {
  return (
    <Flex flexDirection="row" flexWrap="wrap" alignItems="center">
      {curInteractives.options.map((o: any) => (
        <Flex mb="stack._5XS" mr="inline._5XS">
          <CustomButton
            size='SM'
            key={o.uuid}
            onClick={() => {
              sendMessage(o);
              //Evento para tagueamneto do GA
              pushDataLayer({
                event: "event",
                eventCategory: "livelo:webchat",
                eventAction: "clique:botao",
                eventLabel: "webchat" + ":" + o.content, // o.content é para pegar todos os demais botões a partir do primeiro clicado.
              });
            }}>
            {o.content}
          </CustomButton>
        </Flex>
      ))}
    </Flex>
  );
};

export default Buttons;