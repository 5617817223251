import { Flex } from '@livelo/alchemy-web';
import { CustomButton } from './atoms/CustomButton';

const Buttons = ({
  curInteractives,
  sendAttachment,
}: {
  curInteractives: any;
  sendAttachment: any;
}) => {
  return (
    <Flex flexDirection="column">
      {curInteractives.options.map((o: any) => (
        <Flex mb="stack._5XS">
          <CustomButton key={o.uuid} onClick={() => sendAttachment(o.value)}>
            {o.content}
          </CustomButton>
        </Flex>
      ))}
    </Flex>
  );
};

export default Buttons;
